@import url(https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FAF3DD;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.navbarItem {
    font-family: 'Cormorant SC', serif;
    margin-left: 1vw;
    text-decoration: none;
    font-size: large;
    color: white;
}

.navbarItem:hover {
    color: white;
}
.customNavbar {
    padding-right: 1rem;
}

.dropdown {
    margin-left: auto;
    justify-content: end;
    font-size: large;
}

#nav-dropdown{
    color: white;
}

.huglifeBrand {
    font-family: 'Cormorant SC', serif;
    font-size: x-large;
    color: white;
    margin-right: auto;
    margin-left: 1vw;
    text-decoration: none;
}

.huglifeBrand:hover {
    color: white;
}
/* .episodeContainer {
    width: 80%;
} */

.episodeCard {
    padding: 2vw;
    background-color: teal;
}

.recentHeading {
    text-align: center;
    color: #FAF3DD;
}

.pagination {
    margin: auto;
}
.singleEpisode {
    background-color:  #285943;
    color: #FAF3DD;
    padding-left: 3vw;
    padding-right: 3vw;
}

.customPlayer {
    width: 100%;
    max-width: 600px;
}
a.page-link {
    color: #285943;
}
.aboutImage{
    display: block;
    margin: auto;
    max-height: 100%;
}

.aboutCol{
    color: white;
}

.sourceAnchor {
    text-decoration: none;
    color: white;
}

.sourceAnchor:hover {
    color: white;
}

.bannerContainer {
    text-align: center;
}

.bannerImage {
    width: 75%;
}

.sourceCard {
    text-align: center;
}

.iconCard {
    justify-content: center;
    text-align: center;
    background-color: #285943;
    max-width: 100px;
    max-height: 125px;
    padding: 0px;
}

.aboutAnchor {
    color: white;
    text-decoration: none;
}

.aboutAnchor:hover{
    color: white;
    text-decoration: underline;
}
.sendButton {
    background-color: #285943;
    color: #FAF3DD;
}
.customFooter {
    text-align: center;
    margin-top: 2vh;
    color: #FAF3DD;
}
