.aboutImage{
    display: block;
    margin: auto;
    max-height: 100%;
}

.aboutCol{
    color: white;
}

.sourceAnchor {
    text-decoration: none;
    color: white;
}

.sourceAnchor:hover {
    color: white;
}

.bannerContainer {
    text-align: center;
}

.bannerImage {
    width: 75%;
}

.sourceCard {
    text-align: center;
}

.iconCard {
    justify-content: center;
    text-align: center;
    background-color: #285943;
    max-width: 100px;
    max-height: 125px;
    padding: 0px;
}

.aboutAnchor {
    color: white;
    text-decoration: none;
}

.aboutAnchor:hover{
    color: white;
    text-decoration: underline;
}