.navbarItem {
    font-family: 'Cormorant SC', serif;
    margin-left: 1vw;
    text-decoration: none;
    font-size: large;
    color: white;
}

.navbarItem:hover {
    color: white;
}
.customNavbar {
    padding-right: 1rem;
}

.dropdown {
    margin-left: auto;
    justify-content: end;
    font-size: large;
}

#nav-dropdown{
    color: white;
}

.huglifeBrand {
    font-family: 'Cormorant SC', serif;
    font-size: x-large;
    color: white;
    margin-right: auto;
    margin-left: 1vw;
    text-decoration: none;
}

.huglifeBrand:hover {
    color: white;
}